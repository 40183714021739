export const ufList = [
  { id: 1, name: "AC" },
  { id: 2, name: "AL" },
  { id: 3, name: "AM" },
  { id: 4, name: "AP" },
  { id: 5, name: "BA" },
  { id: 6, name: "CE" },
  { id: 7, name: "DF" },
  { id: 8, name: "ES" },
  { id: 9, name: "GO" },
  { id: 10, name: "MA" },
  { id: 11, name: "MG" },
  { id: 12, name: "MS" },
  { id: 13, name: "MT" },
  { id: 14, name: "PA" },
  { id: 15, name: "PB" },
  { id: 16, name: "PE" },
  { id: 17, name: "PI" },
  { id: 18, name: "PR" },
  { id: 19, name: "RJ" },
  { id: 20, name: "RN" },
  { id: 21, name: "RO" },
  { id: 22, name: "RR" },
  { id: 23, name: "RS" },
  { id: 24, name: "SC" },
  { id: 25, name: "SE" },
  { id: 26, name: "SP" },
  { id: 27, name: "TO" },
];
